import {useEffect, useState} from "react";
import axios from "axios";
import React from "react";
import Header from "./Header";
import {Alert, Button, Snackbar, TextField, Typography} from "@mui/material";

interface ExchangeRate {
    USDRUB: number | null;
    RMBRUB: number | null;
    USDRMB: number | null;
}

const SetExchangeRate = () => {
    const [error, setError] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [exchangeRateData, setExchangeRateData] = useState<ExchangeRate>({
        USDRMB: 0,
        USDRUB: 0,
        RMBRUB: 0,
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CARGO_HOST}/api/v1/user/getExchangeRate`);
                setExchangeRateData(response.data.data);
            } catch (err) {
                setError(err.message);
                setIsErrorVisible(true);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const jsonData = JSON.stringify(exchangeRateData, null, 2);

        try {
            const _ = await axios.patch(`${process.env.REACT_APP_CARGO_HOST}/api/v1/order/setExchangeRate`,
                jsonData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            setIsSuccess(true);
        } catch (err) {
            setError(err.message);
            setIsErrorVisible(true);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        const numericFields = [
            'USDRMB', 'USDRUB', 'RMBRUB'
        ];

        const parsedValue = numericFields.includes(name)
            ? parseFloat(value.replace(',', '.')) || null
            : value;

        if (name.includes('.')) {
            const [mainKey, nestedKey] = name.split('.');
            setExchangeRateData(prevData => ({
                ...prevData,
                [mainKey]: {
                    ...prevData[mainKey],
                    [nestedKey]: parsedValue,
                },
            }));
        } else {
            setExchangeRateData(prevData => ({
                ...prevData,
                [name]: parsedValue,
            }));
        }
    }

    return (
        <>
            <Header/>
            <div className="main-manager">
                <form onSubmit={handleSubmit} className="manager-form exchange-rate-form">
                    <Typography variant="h6" gutterBottom>
                        Курс обмена валют
                    </Typography>
                    <TextField
                        label="USD-RUB"
                        value={exchangeRateData.USDRUB || ''}
                        onChange={handleChange}
                        name="USDRUB"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="number"
                        slotProps={{
                            htmlInput: {
                                step: '0.01',
                                min: '0'
                            }
                        }}
                    />
                    <TextField
                        label="USD-RMB"
                        value={exchangeRateData.USDRMB || ''}
                        onChange={handleChange}
                        name="USDRMB"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="number"
                        slotProps={{
                            htmlInput: {
                                step: '0.01',
                                min: '0'
                            }
                        }}
                    />
                    <TextField
                        label="RMB-RUB"
                        value={exchangeRateData.RMBRUB || ''}
                        onChange={handleChange}
                        name="RMBRUB"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="number"
                        slotProps={{
                            htmlInput: {
                                step: '0.01',
                                min: '0'
                            }
                        }}
                    />
                    {isErrorVisible && (
                        <Snackbar open={true} autoHideDuration={6000}>
                            <Alert severity="error" onClose={() => setIsErrorVisible(false)}>
                                {error}
                            </Alert>
                        </Snackbar>
                    )}

                    {/* Success Message */}
                    {isSuccess && (
                        <Snackbar open={true} autoHideDuration={6000}>
                            <Alert severity="success" onClose={() => setIsErrorVisible(false)}>
                                Курс успешно обновлен
                            </Alert>
                        </Snackbar>
                    )}

                    <div className="manager-btn-group">
                        <Button type="submit" variant="contained" color="primary">
                            Обновить
                        </Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default SetExchangeRate;