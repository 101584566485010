import React, {useEffect, useState} from "react";
import "../../styles/Table.sass";
import TabBtnBlock from "./TabBlock";
import OrderDetailsGood from "./OrderDetailsGood";
import OrderDetailsDelivery from "./OrderDetailsDelivery";
import logo from "../../assets/images/logo.png";
import OrderDetailsPayment from "./OrderDetailsPayment";
import axios from 'axios';
import {useParams} from "react-router-dom";

export const Table = () => {
    const [orderData, setOrderData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const { orderId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CARGO_HOST}/api/v1/user/getOrder/${orderId}`);
                setOrderData(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    return (
        <div className="table">
            <div className="table-header">
                <img className="logo" alt="asap-cargo" src={logo} />
                <div className="table-header-content">
                    <div className="company-name">ASAP Cargo</div>
                    <p className="company-contact">
                        <span className="contact-text">Ваш менеджер: <span className="contact-text-manager-name">Олег</span></span>
                    </p>
                    <p className="company-contact">
                        <span className="contact-text">Почта:&nbsp;</span>
                        <a href="mailto:o.erbakhaev@asap-cargo.ru" rel="noopener noreferrer" target="_blank">
                            <span className="company-contact-text">o.erbakhaev@asap-cargo.ru</span>
                        </a>
                    </p>
                    <p className="company-contact">
                        <span className="contact-text">Telegram:&nbsp;</span>
                        <a href="https://t.me/oleg_asap_cargo" rel="noopener noreferrer" target="_blank">
                            <span className="company-contact-text">@oleg_asap_cargo</span>
                        </a>
                    </p>
                </div>
            </div>
            <div className="title-username">Расчет для { orderData.data.clientName }</div>
            <TabBtnBlock activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
            {activeIndex === 0 && <OrderDetailsGood orderData={orderData} />}
            {activeIndex === 1 && <OrderDetailsDelivery orderData={orderData} />}
            {activeIndex === 2 && <OrderDetailsPayment orderData={orderData} />}
        </div>
    );
};