import React from "react";

interface TabItem {
    text: string;
    index: number;
}

const TabBtn = ({ className = '', text, isActive, onClick }) => {
    return (
        <div
            className={`frame ${className} ${isActive ? '' : 'tab-btn'}`}
            onClick={onClick}
        >
            <div className="text-wrapper">{text}</div>
        </div>
    );
};

const TabBtnBlock = ({ activeIndex, setActiveIndex }) => {
    const tabItems: TabItem[] = [
        { text: "Товар", index: 0 },
        { text: "Доставка", index: 1 },
        { text: "Оплата", index: 2 },
    ];

    return (
        <div className="tab-btn-block">
            {tabItems.map(tab => (
                <TabBtn
                    key={tab.index}
                    text={tab.text}
                    isActive={activeIndex === tab.index}
                    onClick={() => setActiveIndex(tab.index)} // Use the provided function
                />
            ))}
        </div>
    );
};

export default TabBtnBlock;