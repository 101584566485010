import React, {useState} from "react";
import PieChart from "./PieChart";

const OrderDetailsPayment = ({ orderData }) => {
    return (
        <div className="order-good-details">
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">К оплате с быстрой доставкой:</div>
                <div
                    className="order-good-text-wrapper-value">₽{orderData.data.cost.finalCostFastDeliveryRUB.toFixed(0)}</div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">К оплате с обычной доставкой:</div>
                <div
                    className="order-good-text-wrapper-value">₽{orderData.data.cost.finalCostSlowDeliveryRUB.toFixed(0)}</div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Способ оплаты:</div>
                <div className="order-good-text-wrapper-value">перевод на карту</div>
            </div>
            <TextToManagerBtn></TextToManagerBtn>
            <CalculatorBtn orderData={ orderData }></CalculatorBtn>
        </div>
    );
};

const TextToManagerBtn = () => {
    return (
        <a className="default-btn-wrapper" href="https://t.me/oleg_asap_cargo" rel="noopener noreferrer" target="_blank">
            <div className="btn">Написать менеджеру</div>
        </a>
    );
};

const CalculatorBtn = ({ orderData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <div className="default-btn-wrapper" onClick={handleOpenModal}>
                <div className="btn">Рассчитать доходность</div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal} data={orderData.data}/>
        </div>
    );
};

const Modal = ({isOpen, onClose, data}) => {
    if (!isOpen) return null;

    const [price, setPrice] = useState('');

    const handlePriceInputChange = (e) => {
        const value = e.target.value;
        setPrice(value);
    };

    const [deliveryFromMowPrice, setDeliveryFromMowPrice] = useState('');

    const handleDeliveryFromMowPriceInputChange = (e) => {
        const value = e.target.value;
        setDeliveryFromMowPrice(value);
    };

    const [marketplaceFee, setMarketplaceFee] = useState('');

    const handleMarketplaceFeeInputChange = (e) => {
        const value = e.target.value;
        setMarketplaceFee(value);
    };

    const [fastDelivery, setFastDelivery] = useState(false);

    const handleFastDeliveryInputChange = (e) => {
        const value = e.target.checked;
        setFastDelivery(value);
    };

    let [usnSixPercents, setUsnSixPercents] = useState(false);

    const handleUsnSixPercentsInputChange = (e) => {
        const value = e.target.checked;
        setUsnIncome(false);
        setUsnSixPercents(value);
    };

    let [usnIncome, setUsnIncome] = useState(false);

    const handleUsnIncomeInputChange = (e) => {
        const value = e.target.checked;
        setUsnSixPercents(false)
        setUsnIncome(value);
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button onClick={onClose} className="chart-close-btn">&#128683;</button>
                <h2 className="chart-name">Калькулятор доходности</h2>
                <div className="input-chart-fields">
                    <div className="input-chart-group">
                        <label htmlFor="price" className="chart-input-form-text">Цена реализации: </label>
                        <input
                            type="number"
                            value={price}
                            placeholder="1000 руб."
                            onChange={handlePriceInputChange}
                            className="input-field-chart-group"
                        />
                    </div>
                    <div className="input-chart-group">
                        <label htmlFor="deliveryFromMowPrice" className="chart-input-form-text">Стоимость доставки из
                            Москвы: </label>
                        <input
                            type="number"
                            value={deliveryFromMowPrice}
                            placeholder="1000 руб."
                            onChange={handleDeliveryFromMowPriceInputChange}
                            className="input-field-chart-group"
                        />
                    </div>
                    <div className="input-chart-group">
                        <label htmlFor="price" className="chart-input-form-text">Комиссия маркетплейса за 1 ед.: </label>
                        <input
                            type="number"
                            value={marketplaceFee}
                            placeholder="1000 руб."
                            onChange={handleMarketplaceFeeInputChange}
                            className="input-field-chart-group"
                        />
                    </div>
                    <div className="input-chart-group">
                        <label htmlFor="price" className="chart-input-form-text">Быстрая доставка: </label>
                        <input
                            type="checkbox"
                            onChange={handleFastDeliveryInputChange}
                            className="input-field-chart-group"
                        />
                    </div>
                    <div className="chart-input-text">Налогообложение</div>
                    <div className="input-chart-group">
                        <label htmlFor="price" className="chart-input-form-text">УСН 6%: </label>
                        <input
                            type="checkbox"
                            checked={usnSixPercents}
                            onChange={handleUsnSixPercentsInputChange}
                            className="input-field-chart-group"
                        />
                    </div>
                    <div className="input-chart-group">
                        <label htmlFor="price" className="chart-input-form-text">УСН (доходы - расходы): </label>
                        <input
                            type="checkbox"
                            checked={usnIncome}
                            onChange={handleUsnIncomeInputChange}
                            className="input-field-chart-group"
                        />
                    </div>
                </div>
                <PieChart data={data} price={price} deliveryFromMowPrice={deliveryFromMowPrice}
                          marketplaceFee={marketplaceFee} isFastDelivery={fastDelivery} isUsnIncome={usnIncome}
                            isSixPercents={usnSixPercents}
                />
            </div>
        </div>
    );
};

export default OrderDetailsPayment;