import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, TextField, Button, TablePagination, Box, Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/ru';
import axios from 'axios';
import Header from "./Header";
import dayjs from "dayjs";

const OrderTable = () => {
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalOrderCount, setTotalOrderCount] = useState(0);

    const [orders, setOrders] = useState([]);
    const [filters, setFilters] = useState({
        clientName: '',
        productName: '',
        from: null,
        dueTo: null,
    });

    const fetchOrders = async () => {
        try {
            const fromTimestamp = filters.from ? Math.floor(new Date(filters.from).getTime() / 1000) : null;

            let dueToTimestamp = null;
            if (filters.dueTo) {
                const dueDate = new Date(filters.dueTo);
                dueDate.setHours(23, 59, 59, 999);
                dueToTimestamp = Math.floor(dueDate.getTime() / 1000);
            }

            const response = await axios.get(`${process.env.REACT_APP_CARGO_HOST}/api/v1/order/getOrders`, {
                params: {
                    ...filters,
                    from: fromTimestamp,
                    dueTo: dueToTimestamp,
                    page,
                    limit,
                },
                withCredentials: true,
            });
            const data = response.data.data;
            setOrders(data.orders);
            setTotalOrderCount(data.pagination.totalCount);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [filters, page, limit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleDateChange = (newValue: dayjs.Dayjs | null, fieldName: string) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [fieldName]: newValue ? newValue.format('YYYY-MM-DD') : null, // Format to string if not null
        }));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setLimit(parseInt(e.target.value));
        setPage(0);
    };

    return (
        <>
            <Header />
            <Box
                sx={{
                    width: 900,
                    mt: 12,
                    position: 'relative',
                    top: 0
                }}
            >
                <Typography variant="h6" marginBottom={2}>Просчеты</Typography>
                <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                >
                    <TextField
                        label="Имя клиента"
                        name="clientName"
                        value={filters.clientName}
                        onChange={handleInputChange}
                        id="outlined-basic"
                    />
                    <TextField
                        label="Товар"
                        name="productName"
                        value={filters.productName}
                        onChange={handleInputChange}
                        id="outlined-basic"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}
                                          adapterLocale="ru"
                    >
                        <DatePicker
                            label="С"
                            name="from"
                            value={filters.from ? dayjs(filters.from) : null}
                            onChange={(newValue) => handleDateChange(newValue, 'from')}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                        <DatePicker
                            label="По"
                            name="dueTo"
                            value={filters.from ? dayjs(filters.dueTo) : null}
                            onChange={(newValue) => handleDateChange(newValue, 'dueTo')}
                        />
                    </LocalizationProvider>
                </Box>

                <TableContainer
                    component={Paper}
                    sx={{
                        mt: 3
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Номер заказа</TableCell>
                                <TableCell>Имя клиента</TableCell>
                                <TableCell>Продукт</TableCell>
                                <TableCell>Дата создания</TableCell>
                                <TableCell>Ссылка</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((order) => (
                                <TableRow key={order.uuid}>
                                    <TableCell>{order.uuid}</TableCell>
                                    <TableCell>{order.clientName}</TableCell>
                                    <TableCell>{order.productName}</TableCell>
                                    <TableCell>{new Date(order.createdAt).toLocaleString()}</TableCell>
                                    <TableCell><a rel="noopener noreferrer" target="_blank"
                                                  href={`https://asap-cargo.ru/order/${order.uuid}`}>Открыть</a></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalOrderCount}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>
        </>
    );
};

export default OrderTable;
