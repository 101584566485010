import React from "react";
import {useNavigate} from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();

    const handleMakeCalculation = () => {
        navigate("/manager/place-order");
    }

    const handleSetExchangeRate = () => {
        navigate("/manager/exchange-rate");
    }

    const handleOrders = () => {
        navigate("/manager/orders");
    }

    return (
        <div className="manager-header">
            <div className="nav" onClick={handleMakeCalculation}>Сделать просчет</div>
            <div className="nav" onClick={handleSetExchangeRate}>Изменить курс валют</div>
            <div className="nav" onClick={handleOrders}>Заказы</div>
        </div>
    )
}

export default Header;