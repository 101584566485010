import React from 'react';
import { Pie } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data, price, deliveryFromMowPrice, marketplaceFee, isFastDelivery, isSixPercents, isUsnIncome }) => {
    const { unitCost, amount, freight, buyOutFeeRUB, additionalPackageCostUSD, insuranceCostCNY } = data.cost;
    const { RMBRUB, USDRUB } = data.exchangeRate;

    const unitCostPrice = unitCost  * RMBRUB;
    const freightPrice = (freight / amount) * RMBRUB;
    const buyOutPrice = buyOutFeeRUB / amount;
    const packagePrice = (additionalPackageCostUSD / amount) * USDRUB;
    const insurancePrice = (insuranceCostCNY / amount) * RMBRUB;

    let deliveryPrice: number;
    if (isFastDelivery) {
        deliveryPrice = data.productInfo.deliveryOpt[0].cost * USDRUB / amount;
    } else {
        deliveryPrice = data.productInfo.deliveryOpt[1].cost * USDRUB / amount;
    }

    let intMarketplaceFee = 0
    if (marketplaceFee != "") {
        intMarketplaceFee = parseInt(marketplaceFee);
    }

    const totalCostWithoutTaxes = unitCostPrice + freightPrice + buyOutPrice + packagePrice + insurancePrice + deliveryPrice + (deliveryFromMowPrice / amount) + intMarketplaceFee;

    let taxCost = 0
    if (isSixPercents) {
        taxCost = (price / 100) * 6;
    } else if (isUsnIncome) {
        taxCost = ((price - totalCostWithoutTaxes) / 100) * 15;
    }

    const income = price - (totalCostWithoutTaxes + taxCost);

    const labels = [
        'Стоимость 1 ед. товара',
        'Фрахт',
        'Выкуп',
        'Упаковка',
        'Страховка',
        'Доставка',
        'Налоги',
        'Доставка из Москвы',
        'Комиссия маркетплейса',
        'Доход'
    ];

    const costData = [
        unitCostPrice,
        freightPrice,
        buyOutPrice,
        packagePrice,
        insurancePrice,
        deliveryPrice,
        taxCost,
        deliveryFromMowPrice / amount,
        intMarketplaceFee,
        income
    ]

    const chartData = {
        labels: labels,
        datasets: [{
            data: costData,
            backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#FF6A81',
                '#A239CA',
                '#F7B801',
                '#EA5455',
                '#54d4ea',
                '#4560e1',
                '#278664'
            ]
        }]
    };

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const value = tooltipItem.raw;
                        let sum = 0
                        for (let i = 0; i < costData.length; i++) {
                            sum += costData[i];
                        }
                        const percentage = ((value / sum) * 100).toFixed(2);
                        return `₽${value.toFixed(2)} (${percentage}%)`;
                    }
                }
            },
        }
    };

    return (
        <div className="chart-bottom">
            <Pie data={chartData} options={options} />
            <div className="chart-income-text">Доходность с 1 ед.: { income.toFixed(2) } руб.</div>
        </div>
    );
};

export default PieChart;
