import React from "react";

const OrderDetailsDelivery = ({ orderData }) => {
    return (
        <div className="order-good-details">
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Вес:</div>
                <div className="order-good-text-wrapper-value">{orderData.data.productInfo.scope.weight.toFixed(2)} кг
                </div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Кол-во грузомест:</div>
                <div
                    className="order-good-text-wrapper-value">{orderData.data.productInfo.scope.amountOfCargoSpace}</div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Объем:</div>
                <div
                    className="order-good-text-wrapper-value">{orderData.data.productInfo.scope.scope.toFixed(5)} (m3)
                </div>
            </div>
            <div className="delivery-table-parent">
                <div className="delivery-table-border-row">
                    <div className="delivery-table-row-name-wrapper">
                        <div className="delivery-table-row-name-text">Время доставки</div>
                    </div>
                    <div className="delivery-table-frame-container">
                        <div className="delivery-table-container-left">
                            <div className="delivery-table-column-title">10-15 дней</div>
                        </div>
                        <div className="delivery-table-container-right">
                            <div className="delivery-table-column-title">15-25 дней</div>
                        </div>
                    </div>
                </div>
                <div className="delivery-table-mid-row">
                    <div className="delivery-table-row-name-wrapper">
                        <div className="delivery-table-row-name-text">Стоимость</div>
                    </div>
                    <div className="delivery-table-frame-container">
                        <div className="delivery-table-mid-row-left">
                            <div className="delivery-table-column-text">${ orderData.data.productInfo.deliveryOpt[0].cost.toFixed(0) }</div>
                        </div>
                        <div className="delivery-table-mid-row-right">
                            <div className="delivery-table-column-text">${ orderData.data.productInfo.deliveryOpt[1].cost.toFixed(0) }</div>
                        </div>
                    </div>
                </div>
                <div className="delivery-table-border-row">
                    <div className="wrapper">
                        <div className="delivery-table-bottom-row-title">
                            <div className="delivery-table-row-name-text">Себестоимость за 1 ед. до Москвы</div>
                        </div>
                    </div>
                    <div className="delivery-table-frame-container">
                        <div className="delivery-table-bottom-row-left">
                            <div className="delivery-table-column-text">¥{ orderData.data.productInfo.deliveryOpt[0].unitCostWithAllFees.totalCostPerUnitCNY.toFixed(0) } (₽{ orderData.data.productInfo.deliveryOpt[0].unitCostWithAllFees.totalCostPerUnitRUB.toFixed(0)})</div>
                        </div>
                        <div className="delivery-table-bottom-row-right">
                            <div className="delivery-table-column-text">¥{ orderData.data.productInfo.deliveryOpt[1].unitCostWithAllFees.totalCostPerUnitCNY.toFixed(0) } (₽{ orderData.data.productInfo.deliveryOpt[1].unitCostWithAllFees.totalCostPerUnitRUB.toFixed(0)})</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetailsDelivery;