import React from 'react';
import ReactDOM from 'react-dom/client';
import Cookies from 'js-cookie';
import { Table } from "./components/Table/Table";
import { Login } from "./components/Manager/Login";
import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from 'react-router-dom';
import PlaceOrder from "./components/Order/PlaceOrder";
import SetExchangeRate from "./components/Manager/SetExchangeRate";
import OrderTable from "./components/Manager/OrdersTable";

const ProtectedRoute = ({ element }) => {
    const isAuthenticated = Cookies.get('asap-admin-auth');

    return isAuthenticated ? element : <Navigate to="/manager/login" replace />;
};

const router = createBrowserRouter([
    {
        path: "order/:orderId",
        element: <Table />,
    },
    {
        path: "manager",
        children: [
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "place-order",
                element: <ProtectedRoute element={<PlaceOrder />} />,
            },
            {
                path: "exchange-rate",
                element: <ProtectedRoute element={<SetExchangeRate />} />
            },
            {
                path: "orders",
                element: <ProtectedRoute element={<OrderTable />} />
            }
        ]
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);
