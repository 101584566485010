import React, {useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const Login = () => {
    const [login, setLogin] = useState('');
    const handleLoginInputChange = (e: { target: { value: string; }; }) => {
        const value = e.target.value;
        setLogin(value);
    };

    const [password, setPassword] = useState('');
    const handlePasswordInputChange = (e: { target: { value: string; }; }) => {
        const value = e.target.value;
        setPassword(value);
    };

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const [error, setError] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const navigate = useNavigate();

    const postData = {
        login,
        password,
    }
    const handleLogin = async () => {
        setIsErrorVisible(false);

        try {
            const response = await axios.post(`${process.env.REACT_APP_CARGO_HOST}/api/v1/auth/adminLogin`,
                postData,
                { withCredentials: true },
                );
            if (response.status == 200) {
                navigate('/manager/place-order');
            }
        } catch (err) {
            setIsErrorVisible(true)
            if (err && err.response) {
                if (err.response.status == 403) {
                    setError("Неправильный логин или пароль")
                } else {
                    setError("Произошла непредвиденная ошибка, напишите администратору @knst_ch");
                }
            } else {
                setError("Произошла непредвиденная ошибка, напишите администратору @knst_ch");
            }
            return;
        }
    };

    return (
        <div className="login-form">
            <div className="login-input-group">
                <label htmlFor="login" className="login-input-form-text">Логин: </label>
                <input
                    type="text"
                    value={login}
                    placeholder="Логин"
                    onChange={handleLoginInputChange}
                    className="input-field-login-group"
                />
            </div>
            <div className="login-input-group">
                <label htmlFor="password" className="login-input-form-text">Пароль: </label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    placeholder="Пароль"
                    onChange={handlePasswordInputChange}
                    className="input-field-login-group"
                />
                <button
                    type="button"
                    onClick={toggleShowPassword}
                    className="login-input-group-show-btn"
                >
                    {showPassword ? 'Скрыть пароль' : 'Показать пароль'}
                </button>
            </div>
            {
                isErrorVisible && (
                    <div className="err-window">{error}</div>
                )
            }
            <button type="submit" onClick={handleLogin } className="login-btn">
                <div className="text-wrapper">Войти</div>
            </button>
        </div>
    )
}