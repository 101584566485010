import axios from "axios";
import React, {useEffect, useState} from "react";
import Header from "../Manager/Header";
import {Alert, Box, Button, Fade, InputAdornment, TextField, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface OrderScope {
    amountOfCargoSpace: number | null;
    length: number | null;
    width: number | null;
    height: number | null;
    oneCargoSpaceWeight: number | null;
}

interface DeliveryCost {
    fastDeliveryCost: number | null;
    slowDeliveryCost: number | null;
}

interface FormData {
    client: string | null;
    productName: string | null;
    productCost: number | null;
    unitAmount: number | null;
    freight: number | null;
    packageCost: number | null;
    productionTime: string | null;
    orderScope: OrderScope;
    deliveryCost: DeliveryCost;
}

interface ExchangeRate {
    USDRUB: number;
    RMBRUB: number;
    USDRMB: number;
}

const dimensionsMap = new Map<string, string>();
dimensionsMap.set("length", "Длина");
dimensionsMap.set("width", "Ширина");
dimensionsMap.set("height", "Высота");

const PlaceOrder = () => {
    const [formData, setFormData] = useState<FormData>({
        client: null,
        productName: null,
        productCost: null,
        unitAmount: null,
        freight: null,
        packageCost: null,
        productionTime: null,
        orderScope: {
            amountOfCargoSpace: null,
            length: null,
            width: null,
            height: null,
            oneCargoSpaceWeight: null,
        },
        deliveryCost: {
            fastDeliveryCost: null,
            slowDeliveryCost: null,
        },
    });

    const [copyMessage, setCopyMessage] = useState('Скопировать ссылку на просчет');
    const [isOriginalTextVisible, setIsOriginalTextVisible] = useState(true);
    const handleCopyToClipboard = () => {
        const url = `https://asap-cargo.ru/order/${orderUuid}`;

        navigator.clipboard.writeText(url)
            .then(() => {
                    setCopyMessage('Ссылка скопирована!');
                    setIsOriginalTextVisible(false);

                    setTimeout(() => {
                        setCopyMessage('Скопировать ссылку на просчет');
                    }, 2000);
            })
            .catch(err => {
                console.error('Ошибка копирования: ', err);
            });
    };

    const [error, setError] = useState('');
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [isErrorVisible, setIsErrorVisible] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const numericFields = [
            'productCost', 'unitAmount', 'freight', 'packageCost',
            'orderScope.amountOfCargoSpace', 'orderScope.length', 'orderScope.width',
            'orderScope.height', 'orderScope.oneCargoSpaceWeight',
            'deliveryCost.fastDeliveryCost', 'deliveryCost.slowDeliveryCost',
        ];

        if (name.includes('.')) {
            const [mainKey, nestedKey] = name.split('.');
            setFormData(prevData => ({
                ...prevData,
                [mainKey]: {
                    ...prevData[mainKey],
                    [nestedKey]: numericFields.includes(name) ? parseFloat(value) || null : value,
                },
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: numericFields.includes(name) ? parseFloat(value) || null : value,
            }));
        }
    };

    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState(null);
    const handleFileChange = (e) => {
        const selectedFile = e.target.files?.[0];
        setFile(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const [orderUuid, setOrderUuid] = useState('');
    const [isOrderUuidVisible, setOrderUuidVisible] = useState(false);

    const handleSubmit = async (e) => {
        setIsErrorVisible(false);

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return
        }

        e.preventDefault();
        const jsonData = JSON.stringify(formData, null, 2);
        const uploadData = new FormData();

        try {
            const orderResponse = await axios.post(
                `${process.env.REACT_APP_CARGO_HOST}/api/v1/order/storeOrder`,
                jsonData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            uploadData.append("orderId", orderResponse.data.data.order.id);
            uploadData.append("ref", file as Blob)

            const _ = await axios.post(
                `${process.env.REACT_APP_CARGO_HOST}/api/v1/order/attachPhotoReference`,
                uploadData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            const orderUuidResponse = await axios.get(
                `${process.env.REACT_APP_CARGO_HOST}/api/v1/order/getUuid/${orderResponse.data.data.order.id}`,
                {
                    withCredentials: true,
                }
            )
            setOrderUuid(orderUuidResponse.data.data.order_uuid)
            setOrderUuidVisible(true)
        } catch (err) {
            setIsErrorVisible(true)
            if (err && err.response) {
                if (err.response.status == 403) {
                    setError("Нет доступа")
                } else {
                    setError("Произошла непредвиденная ошибка, напишите администратору @knst_ch");
                }
            } else {
                setError("Произошла непредвиденная ошибка, напишите администратору @knst_ch");
            }
            return;
        }
    };

    const validateForm = () => {
        const newErrors: Record<string, string> = {};
        if (!formData.client) newErrors.client = 'Client is required.';
        if (!formData.productName) newErrors.productName = 'Product name is required.';
        if (isNaN(formData.productCost)) newErrors.productCost = 'Product cost is required.';
        if (isNaN(formData.unitAmount)) newErrors.unitAmount = 'Unit amount is required.';
        if (isNaN(formData.freight)) newErrors.freight = 'Freight is required.';
        if (isNaN(formData.packageCost)) newErrors.packageCost = 'Package cost is required.';
        if (!formData.productionTime) newErrors.productionTime = 'Production time is required.';

        Object.keys(formData.orderScope).forEach((key) => {
            if (isNaN(formData.orderScope[key as keyof OrderScope])) {
                newErrors[`orderScope.${key}`] = `${key} is required.`;
            }
        });

        Object.keys(formData.deliveryCost).forEach((key) => {
            if (isNaN(formData.deliveryCost[key as keyof DeliveryCost])) {
                newErrors[`deliveryCost.${key}`] = `${key} is required.`;
            }
        });

        return newErrors;
    };

    const resetForm = () => {
        setFormData({
            client: null,
            productName: null,
            productCost: null,
            unitAmount: null,
            freight: null,
            packageCost: null,
            productionTime: null,
            orderScope: {
                amountOfCargoSpace: null,
                length: null,
                width: null,
                height: null,
                oneCargoSpaceWeight: null,
            },
            deliveryCost: {
                fastDeliveryCost: null,
                slowDeliveryCost: null,
            },
        });
        setFile(null);
        setErrors({});
        setIsErrorVisible(false);
        setOrderUuid('');
    };

    const [exchangeRateData, setExchangeRateData] = useState<ExchangeRate>({
        USDRMB: 0,
        USDRUB: 0,
        RMBRUB: 0,
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CARGO_HOST}/api/v1/user/getExchangeRate`);
                setExchangeRateData(response.data.data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Header/>
            {/*<div className="exchange-rate-place-order">*/}
            {/*    <h2>Курсы валют</h2>*/}
            {/*    <div className="exchange-rate-place-order-rates">*/}
            {/*        <div>USD-RUB: {exchangeRateData.USDRUB}</div>*/}
            {/*        <div>RMB-RUB: {exchangeRateData.RMBRUB}</div>*/}
            {/*        <div>USD-RMB: {exchangeRateData.USDRMB}</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                width: 632,
                    mt: 10,
            }}>
                <Typography variant="h6" marginBottom={2}>Основная информация</Typography>
                <Grid container spacing={2}>
                    <Grid sx={{
                        width: 308,
                    }}>
                        <TextField
                            label="Клиент"
                            name="client"
                            required={true}
                            value={formData.client || ''}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors.client)}
                            helperText={errors.client}
                        />
                    </Grid>
                    <Grid sx={{
                        width: 308,
                    }}>
                        <TextField
                            label="Название товара"
                            name="productName"
                            required={true}
                            value={formData.productName || ''}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors.productName)}
                            helperText={errors.productName}
                        />
                    </Grid>
                    <Grid sx={{
                        width: 308,
                    }}>
                        <TextField
                            label="Количество"
                            name="unitAmount"
                            required={true}
                            type="number"
                            slotProps={{
                                htmlInput: {
                                    min: '0',
                                    step: '1'
                                }
                            }}
                            value={formData.unitAmount !== null ? formData.unitAmount : ''}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors.unitAmount)}
                            helperText={errors.unitAmount}
                        />
                    </Grid>
                    <Grid sx={{
                        width: 308,
                    }}>
                        <TextField
                            label="Время производства"
                            name="productionTime"
                            required={true}
                            value={formData.productionTime || ''}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors.productionTime)}
                            helperText={errors.productionTime}
                        />
                    </Grid>
                    <Grid sx={{
                        width: 308,
                    }}>
                        <TextField
                            label="Стоимость за 1 ед."
                            name="productCost"
                            required={true}
                            type="number"
                            slotProps={{
                                htmlInput: {
                                    min: '0',
                                    step: '0.01'
                                },
                                input: {
                                    startAdornment: <InputAdornment position="start">¥</InputAdornment>
                                }
                            }}
                            id="outlined-start-adornment"
                            value={formData.productCost !== null ? formData.productCost : ''}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors.productCost)}
                            helperText={errors.productCost}
                        />
                    </Grid>
                    <Grid sx={{
                        width: 308,
                    }}>
                        <TextField
                            label="Доставка по Китаю"
                            name="freight"
                            required={true}
                            type="number"
                            slotProps={{
                                htmlInput: {
                                    min: '0',
                                    step: '0.01'
                                },
                                input: {
                                    startAdornment: <InputAdornment position="start">¥</InputAdornment>
                                }
                            }}
                            value={formData.freight !== null ? formData.freight : ''}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors.freight)}
                            helperText={errors.freight}
                        />
                    </Grid>
                    <Grid sx={{
                        width: 308,
                    }}>
                        <TextField
                            label="Стоимость упаковки"
                            name="packageCost"
                            required={true}
                            type="number"
                            slotProps={{
                                htmlInput: {
                                    min: '0',
                                    step: '0.01'
                                },
                                input: {
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }
                            }}
                            value={formData.packageCost !== null ? formData.packageCost : ''}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors.packageCost)}
                            helperText={errors.packageCost}
                        />
                    </Grid>
                </Grid>

                <Typography variant="h6" marginTop={3} marginBottom={2}>Габариты товара</Typography>
                <Grid container spacing={2}>
                    <Grid sx={{
                        width: 308,
                    }}>
                        <TextField
                            label="Количество грузомест"
                            name="orderScope.amountOfCargoSpace"
                            required={true}
                            type="number"
                            slotProps={{
                                htmlInput: {
                                    min: '0',
                                    step: '1'
                                }
                            }}
                            value={formData.orderScope.amountOfCargoSpace !== null ? formData.orderScope.amountOfCargoSpace : ''}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors['orderScope.amountOfCargoSpace'])}
                            helperText={errors['orderScope.amountOfCargoSpace']}
                        />
                    </Grid>
                    <Grid sx={{
                        width: 308,
                    }}>
                        <TextField
                            label="Вес одного грузоместа"
                            name="orderScope.oneCargoSpaceWeight"
                            required={true}
                            type="number"
                            slotProps={{
                                htmlInput: {
                                    min: '0',
                                    step: '0.01'
                                },
                                input: {
                                    startAdornment: <InputAdornment position="start">кг</InputAdornment>
                                }
                            }}
                            value={formData.orderScope.oneCargoSpaceWeight !== null ? formData.orderScope.oneCargoSpaceWeight : ''}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors['orderScope.oneCargoSpaceWeight'])}
                            helperText={errors['orderScope.oneCargoSpaceWeight']}
                        />
                    </Grid>
                    {['length', 'width', 'height'].map((dimension) => (
                        <Grid key={dimension} sx={{
                            width: 308,
                        }}>
                            <TextField
                                label={`${dimensionsMap.get(dimension)}`}
                                name={`orderScope.${dimension}`}
                                required={true}
                                type="number"
                                slotProps={{
                                    htmlInput: {
                                        min: '0',
                                        step: '0.01'
                                    },
                                    input: {
                                        startAdornment: <InputAdornment position="start">см</InputAdornment>
                                    }
                                }}
                                value={formData.orderScope[dimension] !== null ? formData.orderScope[dimension] : ''}
                                onChange={handleChange}
                                fullWidth
                                error={Boolean(errors[`orderScope.${dimension}`])}
                                helperText={errors[`orderScope.${dimension}`]}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Typography variant="h6" marginTop={3} marginBottom={2}>Тарифы на доставку</Typography>
                <Grid container spacing={2}>
                    {['fastDeliveryCost', 'slowDeliveryCost'].map((type) => (
                        <Grid key={type} sx={{
                            width: 308,
                        }}>
                            <TextField
                                label={`Тариф ${type === 'fastDeliveryCost' ? 'быстрой' : 'стандартной'} доставки`}
                                name={`deliveryCost.${type}`}
                                required={true}
                                type="number"
                                slotProps={{
                                    htmlInput: {
                                        min: '0',
                                        step: '0.01'
                                    },
                                    input: {
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }
                                }}
                                value={formData.deliveryCost[type] !== null ? formData.deliveryCost[type] : ''}
                                onChange={handleChange}
                                fullWidth
                                error={Boolean(errors[`deliveryCost.${type}`])}
                                helperText={errors[`deliveryCost.${type}`]}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Typography variant="h6" marginTop={3} marginBottom={2}>Фото</Typography>
                <Grid container spacing={2}>
                    <Grid sx={{
                        width: 700,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 3
                    }}>
                        <Button
                            variant="contained"
                            component="label">
                            Загрузить референс
                            <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                            />
                        </Button>
                        {file && (
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                                <AttachFileIcon sx={{ marginRight: 1 }} />
                                <Typography variant="body2">
                                    {file.name} прикреплен
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                {preview && (
                    <Box sx={{ marginTop: 2 }}>
                        <img
                            src={preview}
                            alt="Preview"
                            style={{ maxWidth: '50%', height: 'auto', borderRadius: '4px' }}
                        />
                    </Box>
                )}
                {isErrorVisible && (
                    <Alert severity="error" sx={{mt: 2}}>
                        {error}
                    </Alert>
                )}
                {isOrderUuidVisible && (
                    <Box
                        sx={{
                            mt: 4,
                            p: 2,
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            position: 'relative',
                            overflow: 'hidden',
                            transition: 'backdrop-filter 1s ease-in-out',
                        }}
                        onClick={handleCopyToClipboard}
                    >
                        <Typography
                            sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        >
                            {copyMessage}
                        </Typography>
                    </Box>
                )}
                <Box marginTop={5} display="flex" gap={3}>
                    <Button type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                width: 130,
                                alignItems: 'center',
                            }}
                    >
                        Отправить
                    </Button>
                    <Button type="button"
                            onClick={resetForm}
                            variant="outlined"
                            color="error"
                            sx={{
                                width: 170,
                                alignItems: 'center',
                            }}
                    >
                        Очистить форму
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default PlaceOrder;